import Head from "next/head";
import { useEffect } from "react";

// const OpenWrapScript = ({delay=false}) => {
//   const delayTime = delay ? 7000 : 500;
//   console.log("delayTime", delayTime);
//   useEffect(() => {
//     var PWT = {};
//     var gptRan = false;
//     PWT.jsLoaded = function () {
//       setTimeout(loadGpt(), delayTime);
//     };
//     window.PWT = PWT;
//     (function () {
//       var purl = window.location.href;
//       var url = "//ads.pubmatic.com/AdServer/js/pwt/156066/13586";
//       var profileVersionId = "";
//       if (purl.indexOf("pwtv=") > 0) {
//         var regexp = /pwtv=(.*?)(&|$)/g;
//         var matches = regexp.exec(purl);
//         if (matches.length >= 2 && matches[1].length > 0) {
//           profileVersionId = "/" + matches[1];
//         }
//       }
//       let pwt_js = document.getElementById("pwt_js");
//       if (pwt_js) {
//         console.log("already pwt loaded", pwt_js);
//       } else {
//         var wtads = document.createElement("script");
//         wtads.async = true;
//         wtads.type = "text/javascript";
//         wtads.id = "pwt_js";
//         wtads.src = url + profileVersionId + "/pwt.js";
//         var node = document.getElementsByTagName("script")[0];
//         node.parentNode.insertBefore(wtads, node);
//       }
//     })();
//     var loadGpt = function () {
//       // Check the gptRan flag
//       if (!gptRan) {
//         gptRan = true;
//         let gpt_js = document.getElementById("gpt_js");
//         if (gpt_js) {
//           console.log("gpt not loaded");
//         } else {
//           var gads = document.createElement("script");
//           var useSSL = "https:" == document.location.protocol;
//           gads.src =
//             (useSSL ? "https:" : "http:") +
//             "//securepubads.g.doubleclick.net/tag/js/gpt.js";
//           gads.id = "gpt_js";
//           var node = document.getElementsByTagName("script")[0];
//           node.parentNode.insertBefore(gads, node);
//         }
//       }
//     };
//     // Failsafe to call gpt
//     setTimeout(loadGpt, delayTime);
//   }, []);

//   return (
//     <Head>
//       <script async
//         type="text/javascript"
//         dangerouslySetInnerHTML={{
//           __html: `// this function will act as a lock and will call the GPT API
//           function initAdserver(forced) {
//             console.log("initAdserver forced",forced);
//               if((forced === true && window.initAdserverFlag !== true) || (PWT.TAM_BidsReceived && PWT.ow_BidsReceived)){
//                   window.initAdserverFlag = true;
//                   PWT.TAM_BidsReceived = PWT.ow_BidsReceived = false;
//                   setTimeout(()=>{googletag.pubads().refresh()}, 1000); 
//               }         
//           }`,
//         }}
//       ></script>
//       {/* <script
//         type="text/javascript"
//         dangerouslySetInnerHTML={{
//           __html: `function requestBids(){if(typeof window.PWT !== "undefined" && typeof PWT.requestBids === 'function'){PWT.requestBids(PWT.generateConfForGPT(googletag.pubads().getSlots()), function(adUnitsArray) {PWT.addKeyValuePairsToGPTSlots(adUnitsArray); PWT.ow_BidsReceived = true; window.initAdserver(true); } ); } }`,
//         }}
//       ></script> */}
//     </Head>
//   );
// };

const OpenWrapScript = () => {
  useEffect(() => {
    var PWT = {};
    window.PWT = PWT;
    // Function to load a script dynamically
    const loadScript = (id, src) => {
      if (!document.getElementById(id)) {
        const script = document.createElement("script");
        script.defer = true;
        script.id = id;
        script.src = src;
        document.head.appendChild(script);
      } else {
        console.log(`${id} already loaded`);
      }
    };
 
    // Load OpenWrap script with dynamic profile version
    const loadPwt = () => {
      const purl = window.location.href;
      const baseUrl = "//ads.pubmatic.com/AdServer/js/pwt/156066/13586";
      let profileVersionId = "";
 
      if (purl.includes("pwtv=")) {
        const match = /pwtv=(.*?)(&|$)/.exec(purl);
        if (match && match[1]) {
          profileVersionId = `/${match[1]}`;
        }
      }
  
      loadScript("pwt_js", `${baseUrl}${profileVersionId}/pwt.js`);
      console.log("pwt_js: ", pwt_js)
    };
 
    // Load GPT script
    const loadGpt = () => {
      const useSSL = document.location.protocol === "https:";
      loadScript(
        "gpt_js",
        `${useSSL ? "https:" : "http:"}//securepubads.g.doubleclick.net/tag/js/gpt.js`
      );
    };
 
    // Delay the loading of PWT and GPT scripts
    const pwtTimeout = setTimeout(() => {
      
      loadPwt();
    },500); 
 
    const gptTimeout = setTimeout(() => {
      loadGpt();
    }, 500);  
 
    // Cleanup timeouts on unmount
    return () => {
      clearTimeout(pwtTimeout);
      clearTimeout(gptTimeout);
    };
  }, []);
 
  return (
    <Head>
    <script
  type="text/javascript"
  dangerouslySetInnerHTML={{
    __html: `
      function initAdserver(forced) {
        if (
          (forced === true && window.initAdserverFlag !== true) ||
          (PWT.TAM_BidsReceived && PWT.ow_BidsReceived)
        ) {
          window.initAdserverFlag = true;
          PWT.TAM_BidsReceived = PWT.ow_BidsReceived = false;
          
          setTimeout(() => {
            if (window.googletag) {
              googletag.cmd.push(() => {
                const allSlots = googletag.pubads().getSlots();

                // 🔥 Exclude ATF Ads
                const slotsToRefresh = allSlots.filter(slot => 
                  slot.getAdUnitPath() !== "/1007232/News_tak_Desktop_HP_ATF_728x90" &&
                  slot.getAdUnitPath() !== "/1007232/News_tak_Desktop_HP_ATF_300x250" &&
                 slot.getAdUnitPath() !==  "/1007232/News_tak_Mobile_HP_ATF_300x250" &&
                  slot.getAdUnitPath() !== "/1007232/News_tak_Mobile_HP_Bottom_320x50" &&
                 slot.getAdUnitPath() !==  "/1007232/News_tak_Desktop_ROS_ATF_728x90" &&
                  slot.getAdUnitPath() !== "/1007232/News_tak_Desktop_ROS_ATF_300x250" &&
                   slot.getAdUnitPath() !== "/1007232/News_tak_Mobile_ROS_Bottom_320x50" &&
                   slot.getAdUnitPath() !== "/1007232/News_tak_Mobile_ROS_ATF_300x250"
                );

                if(!window.ATFRefresh){
                 googletag.pubads().refresh();
                 window.ATFRefresh = true;
                }
                if (slotsToRefresh.length > 0 ) {
                  googletag.pubads().refresh(slotsToRefresh);
                }
              });
            }
          }, 1000);
        }
      }
    `,
  }}
></script>

    </Head>
  );
};

export default OpenWrapScript;
